<template>
    <div class="modal-select-dormant-reason">
        <Loading :loading="loading" />
        <div class="textbox">
            <div class="title f-23 p-t-40 m-l-20 m-r-20">휴면 사유를 선택해주세요.</div>
            <ul class="reason flex-fill p-t-25 p-l-30 p-r-20">
                <li
                    @click="onClickItem(item)"
                    class="item flex-row items-center flex-between"
                    :class="{ selected: item.$$selected }"
                    :key="item.id"
                    v-for="item in reasonItems"
                >
                    <div v-html="$translate(item.name)" />
                </li>

                <div class="reason flex-fill p-t-10" v-if="isSelected === true">
                    <TextareaWithX
                        v-model="inputValue"
                        :placeholder="placeholder"
                        :textareaRows="4"
                        :ref="'comment'"
                        :setClass="'content-area m-0'"
                    />
                </div>
            </ul>
            <div class="bottom-button">
                <button
                    class="btn-send-reason f-18"
                    :class="{ disabled: disabled }"
                    @click="onClickBtn(options.status)"
                    :disabled="disabled"
                >
                    휴면하기
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { getPlanNameById } from '@/store/data/product'
import userService from '@/services/user'

export default {
    name: 'ModalSelectDormantReason',
    props: ['options'],
    data: () => ({
        reasonItems: [],
        selectedValue: [],
        inputValue: '',
        disabled: true,
        isSelected: false,
        loading: false,
    }),
    async mounted() {
        await this.dormantOptions()
    },
    watch: {
        selectedValue() {
            if (this.selectedValue.length > 0) {
                this.disabled = false
                this.$emit('disabled', this.disabled)
            } else {
                this.disabled = true
                this.$emit('disabled', true)
            }
        },
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        placeholder() {
            return '휴면 사유를 입력해주세요.'
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || {}
        },
        myPlan() {
            const plan = this.userRatePlans.onGoing.find(o => o.ptype === 'subscription_plan')
            return plan
        },
        planDetail() {
            return this.myPlan ? getPlanNameById(this.myPlan.product_id) : ''
        },
    },
    methods: {
        async dormantOptions() {
            const allReasons = this.$store.getters.inactiveReasons
            this.reasonItems = allReasons.filter(item => item.inactive_type === 'dormant')
        },
        onClickItem(item) {
            this.reasonItems.forEach(o => this.$set(o, '$$selected', false))
            this.$set(item, '$$selected', !item.$$selected)

            this.selectedValue = [item]

            if (this.selectedValue.length > 0) {
                this.disabled = false
                this.$emit('disabled', this.disabled)
            } else {
                this.disabled = true
                this.$emit('disabled', true)
            }

            this.isSelected = item.name === '기타' && item.$$selected
        },
        async onClickBtn() {
            try {
                if (this.planDetail && this.planDetail.name.includes('Marry Fit')) {
                    await this.$modal
                        .basic({
                            body:
                                '메리핏 멤버십 회원의 경우 ‘고객센터’로 요청해주세요. (멤버십 기간 동안 휴면 횟수가 1회로 제한되며, 확인 절차가 필요합니다.)',
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 0) {
                                this.$router.go(-1)
                            }
                        })
                } else {
                    await this.$modal
                        .basic({
                            body:
                                '휴면 상태로 전환합니다. 휴면 상태가 되면 프로필 전달이 중지되고, 내 프로필도 타 회원에게 보이지 않습니다. 보유한 매칭권의 유효기간은 자동으로 연장됩니다.',
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(async idx => {
                            if (idx === 0) {
                                try {
                                    const ret = await userService.dormant({
                                        dormant: !this.me.is_dormant,
                                        inactive_reason_id: this.selectedValue[0].id,
                                        inactive_reason_etc: this.inputValue,
                                    })
                                    if (ret.msg === 'schedules not done') {
                                        await this.$modal.basic({
                                            body:
                                                '아직 만나지 않은 약속이 있거나, 만남 후기를 입력하지 않은 경우 휴면이 불가합니다. 잡힌 약속이 모두 마무리 되고, 만남 후기를 입력한 후에 휴면해주세요.',
                                            buttons: [
                                                {
                                                    label: 'CONFIRM',
                                                    class: 'btn-primary',
                                                },
                                            ],
                                        })
                                        this.$router.go(-1)
                                        return
                                    }

                                    this.loading = true
                                    this.$toast.success('휴면 처리가 완료되었습니다.')
                                    this.loading = false
                                    this.$router.go(-2)
                                    this.$stackRouter.pop()
                                    await this.$store.dispatch('loadMe')
                                } catch (err) {
                                    console.error(err)
                                }
                            }
                        })
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
    },
}
</script>
<style scoped lang="scss">
.modal-select-dormant-reason {
    // @include center;
    position: relative;
    .title {
        font-weight: 600;
        text-align: center;
    }
    .description {
        color: #696969;
        line-height: 19.2px;
    }
    ul {
        margin-bottom: 100px;

        li {
            @include center;
            padding: 17px;
            color: #888888;
            size: 14px;
            background: white;
            margin-bottom: 8px;
            border-radius: 8px;
            border: 1px solid #efdfd2;
            font-weight: 400;

            &.selected {
                color: #151360;
                border: 1px solid #151360;
                font-weight: 600;
            }
        }
    }
    .bottom-button {
        padding: 12px 20px 20px 20px;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #fff9ef;
        .btn-send-reason {
            height: 48px;
            width: 100%;
            border: none;
            border-radius: 36px;
            background: #151360;
            color: white;

            &.disabled {
                background: #d7d5d1;
            }
        }
    }
}
</style>
